export const options = {
	gender: {
		male: 'Male',
		female: 'Female',
		other: 'Other',
	},
	maritalStatus: {
		single: 'Single', //keep for now to preserve existing data
		married: 'Married',
		neverMarried: 'Never Married',
		divorced: 'Divorced',
		separated: 'Separated',
		widow: 'Widow',
		partner: 'Partner',
	},
	clientType: {
		child: 'Outpatient - Child',
		adult: 'Outpatient - Adult',
		'IOP 3 day': 'IOP 3 day',
		'IOP 4 day': 'IOP 4 day',
		'IOP 5 day': 'IOP 5 day',
		'PHP 4 day': 'PHP 4 day',
		'PHP 5 day': 'PHP 5 day',
		'Transition Care': 'Transition Care',
	},
	isActive: {
		active: 'Active',
		inactive: 'Inactive',
	},
};

export const generateSelectOption = (type, value) => {
	const label = options[type][value];

	if (!label) {
		return { value: 'outpatient', label: options.clientType.outpatient };
	}

	return { value: value, label };
};

export const generateAllOptions = (type) => {
	const typeOptions = options[type];
	const result = [];

	for (let key in typeOptions) {
		result.push({ value: key, label: typeOptions[key] });
	}

	return result;
};
